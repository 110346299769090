import React, { ReactElement } from 'react';


const Thankyou = (): ReactElement => {
    return (
        <h1>Thank you for upgrading to a Streamlux paid plan.</h1>
    );
};

export default Thankyou;
